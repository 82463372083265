import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

const Card = ({ article }) => {
  const reDate = /\d+-\d+-\d+/;
  let publishedDate = reDate.exec(article.node.publishedAt);
  const author = article.node.author;
  if (typeof window !== "undefined") {
    if (window.innerWidth < 1100) {
      return (
        <Link to={`/article/${article.node.slug}`} className="uk-link-reset">
          <div
            className="uk-card uk-card-muted "
            style={{
              display: "flex",
              width: "100%",
              marginTop: 40,
              marginBottom: 40,
              paddingRight: "16px",
              paddingLeft: "16px",
            }}
          >
            <div
              className="uk-card-body"
              style={{ display: "flex", padding: 0, width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 720,
                }}
              >
                <p
                  className={"card-category-font uk-margin-remove-bottom"}
                  style={{ alignSelf: "auto" }}
                >
                  {article.node.category.name}
                </p>

                <div style={{ height: 8, width: 8 }} />

                <p
                  className={
                    "card-title-font uk-margin-remove-bottom uk-margin-remove-top"
                  }
                >
                  {article.node.title}
                </p>

                <div style={{ height: 8, width: 8 }} />

                <p
                  className={"card-description-font text-three-line-ellipsis"}
                  style={{ marginTop: 0, marginBottom: 0 }}
                >
                  {article.node.description}
                </p>

                <div style={{ height: 16, width: 8 }} />

                <p style={{ margin: 0 }}>
                  <div style={{ display: "flex", alignSelf: "center" }}>
                    <p
                      className={"author-date-font"}
                      style={{ margin: "0px", alignSelf: "center" }}
                    >
                      {publishedDate + ","}
                    </p>

                    <div style={{ height: 8, width: 8 }} />

                    <p
                      className={"author-date-font text-ellipsis"}
                      style={{
                        margin: "0px",
                        maxWidth: 160,
                      }}
                    >
                      {author.name}
                    </p>
                  </div>
                </p>
              </div>

              <div style={{ display: "flex", flex: 1 }} />
            </div>
          </div>

          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: 1100,
              paddingRight: "16px",
              paddingLeft: "16px",
            }}
          >
            <hr />
          </div>
        </Link>
      );
    }
  }
  return (
    <Link to={`/article/${article.node.slug}`} className="uk-link-reset">
      <div
        className="uk-card uk-card-muted "
        style={{
          display: "flex",
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        <div
          className="uk-card-body"
          style={{ display: "flex", padding: 0, width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 720,
            }}
          >
            <p
              className={"card-category-font uk-margin-remove-bottom"}
              style={{ alignSelf: "auto" }}
            >
              {article.node.category.name}
            </p>
            <div style={{ height: 8, width: 8 }} />

            <p
              className={
                "card-title-font uk-margin-remove-bottom uk-margin-remove-top"
              }
            >
              {article.node.title}
            </p>

            <div style={{ height: 16, width: 16 }} />

            <p
              className={"card-description-font text-three-line-ellipsis"}
              style={{ marginTop: 10 }}
            >
              {article.node.description}
            </p>
          </div>

          <div style={{ display: "flex", flex: 1 }} />

          <div style={{ display: "flex", alignSelf: "center" }}>
            <div style={{ alignSelf: "center" }}>
              <p
                className={"author-name-font text-ellipsis"}
                style={{
                  margin: "4px 16px",
                  maxWidth: 160,
                  textAlign: "right",
                }}
              >
                {author.name}
              </p>
              <p
                className={"author-date-font"}
                style={{ textAlign: "right", margin: "4px 16px" }}
              >
                {publishedDate}
              </p>
            </div>

            <div>
              {author.picture && (
                <Img
                  fixed={author.picture.childImageSharp.fixed}
                  imgStyle={{
                    minWidth: 64,
                    position: "static",
                    borderRadius: "50%",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </Link>
  );
};

export default Card;
