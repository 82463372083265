import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import ArticlesComponent from "../components/articles";
import MainBanner from "../components/mainBanner";
import "../assets/css/main.css";

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <MainBanner />
      <div
        style={{
          marginTop: 60,
          maxWidth: 1100,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <ArticlesComponent articles={data.allStrapiArticle.edges} />
      </div>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    allStrapiArticle(filter: { status: { eq: "published" } }) {
      edges {
        node {
          strapiId
          slug
          title
          description
          publishedAt
          category {
            name
          }
          author {
            name
            picture {
              childImageSharp {
                fixed(width: 64, height: 64) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
