import React from "react";
import styled from "styled-components";
import homeImage from "../images/img-home.jpg";

const BannerContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  min-height: 304px;
`;

const BannerCover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(34, 34, 34, 0.8);
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  align-items: center;
`;

const DesktopBannerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  max-width: 1100px;

  margin-bottom: 44px;
  padding: 0;
`;

const ApplyButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 42px;
  color: black;
  background-color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
`;

const DesktopApplyButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 42px;
  color: black;
  background-color: white;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  align-self: flex-end;
  margin-bottom: 12px;
`;

export default function MainBanner() {
  if (typeof window !== "undefined") {
    if (window.innerWidth < 1100) {
      return (
        <BannerContainer>
          <img src={homeImage} alt="" style={{ objectFit: "cover" }} />

          <BannerCover>
            <div style={{ flex: 1 }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: "44px",
              }}
            >
              <p className={"main-title-mobile uk-margin-remove-bottom"}>
                Tappytoon Tech
              </p>

              <p className={"main-description-mobile uk-margin-remove-bottom"}>
                {
                  <div>
                    글로벌 웹툰 & 웹소설 플랫폼,
                    <br />
                    태피툰을 만드는 사람들의 이야기를 전합니다
                  </div>
                }
              </p>

              <div style={{ width: 24, height: 24 }} />

              <ApplyButton
                href="https://about.tappytoon.com/e7432e6d-189d-4121-9081-00f71159abf5"
                rel="noopener noreferrer"
                target="_blank"
              >
                채용 지원
              </ApplyButton>
            </div>
          </BannerCover>
        </BannerContainer>
      );
    }
  }

  return (
    <BannerContainer>
      <img src={homeImage} alt="" height="304" />
      <BannerCover>
        <div style={{ flex: 1 }} />

        <DesktopBannerContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "auto",
            }}
          >
            <p className={"main-title uk-margin-remove-bottom"}>
              Tappytoon Tech Blog
            </p>

            <p
              className={"main-description-font"}
              style={{ margin: "12px 0 0 0" }}
            >
              글로벌 웹툰 & 웹소설 플랫폼, 태피툰을 만드는 사람들의 이야기를
              전합니다
            </p>
          </div>

          <div style={{ flex: 1 }} />

          <DesktopApplyButton
            href="https://about.tappytoon.com/e7432e6d-189d-4121-9081-00f71159abf5"
            target="_blank"
            rel="noopener noreferrer"
          >
            채용 지원하기
          </DesktopApplyButton>
        </DesktopBannerContainer>
      </BannerCover>
    </BannerContainer>
  );
}
