import React from "react";
import Card from "./card";

const Articles = ({ articles }) => {
  articles.sort((a, b) => {
    return b.node.strapiId - a.node.strapiId;
  });

  return (
    <div>
      {articles.map((article) => {
        return (
          <Card article={article} key={`articles__${article.node.slug}`} />
        );
      })}
    </div>
  );
};

export default Articles;
